import React from "react";
import { Helmet } from "react-helmet";

export default function News() {
  return (
    <main>
      <Helmet>
        <title>Francisco Martinez - Personal Website</title>
        <meta
          name="description"
          content="News. Francisco Martinez. Redesigning the Social through Ethnographic Experimentation."
        />
        <meta
          name="keywords"
          content="anthropology, ethnography, material culture, political discourses, repair practices, francisco martinez"
        />
      </Helmet>

      <div className="news">
        <div className="container">
          <div className="news-item">
            <h1 className="news-header">
              Online Workshop: Theorising Temporalities/Theorising Futures
            </h1>
            <p className="news-date">18 December 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/Theorising Temporalities.jpg"
                alt="News"
                className="news-img"
              />
            </div>
            <div className="news-text ms-4">
              <p>
                13:00 Introduction
                <br />
                <span className="fw-bold">Damián O. Martínez</span> (University
                of Murcia/Viodemos)
              </p>
            </div>
            <div className="news-next ms-4">
              <p className="text-decoration-underline">
                PANEL I – URBAN FUTURES, COLONIAL TEMPORALITIES
              </p>
              <p>
                13:15 Crafting Digital Futures: Temporalities Beyond the
                Rural-Urban Divide
                <br />
                <span className="fw-bold">Prince Guma</span> (British Institute
                in Eastern Africa)
              </p>
              <p>
                13:45 Temporality of failure in urban policymaking:
                Socioeconomic and Spatial Consequences
                <br />
                <span className="fw-bold">Cansu Civelek</span> (University of
                Tübingen/University of Vienna)
              </p>
              <p>
                14:25 Colonised Temporalities: Time and Power in Colonial
                Contexts
                <br />
                <span className="fw-bold">Carmen Ibañez</span> (Free University
                of Berlin)
              </p>
              <p>
                14:55 Commentary, responses, discussion (50’)
                <br />
                <span className="fw-bold">Nina Glick Schiller</span> (University
                of Manchester/Max Planck Institute for Social Anthropology)
              </p>
            </div>
            <div className="news-next ms-4">
              <p className="text-decoration-underline">
                PANEL II – ENVIRONMENTAL FUTURES, WASTE TEMPORALITIES
              </p>
              <p>
                16:05 How Final is Final? The Construction of Finality and
                Safety in the Onkalo Spent Nuclear Fuel Repository
                <br />
                <span className="fw-bold">Francisco Martínez</span> (Tampere
                University)
              </p>
              <p>
                16:35 From Grief to Action: Affective Futures and Collective
                Agency in Times of Environmental Collapse
                <br />
                <span className="fw-bold">Damián O. Martínez</span> (University
                of Murcia/Viodemos)
              </p>
              <p>
                17:20 Commentary, responses, discussion (40’)
                <br />
                <span className="fw-bold">Stephen Reyna</span> (Max Planck
                Institute for Social Anthropology)
              </p>
              <p>
                18:00{" "}
                <span className="fw-bold">
                  Final Discussion and publication plans
                </span>
              </p>
            </div>
          </div>
          <hr></hr>
          <div className="news-item">
            <h1 className="news-header">Infrastructures of (De)Occupation</h1>
            <p className="news-date">21 November 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/News-3.jpeg"
                alt="News"
                className="news-img"
              />
            </div>
            <h3 className="news-section-header">Overview</h3>
            <p className="news-text ms-4 fw-bold">
              This panel series is comprised of two parts: A) Infrastructures of
              Occupation; B) Infrastructures of Liberation.
            </p>
            <p className="news-text ms-4">
              Part A<br />
              <span className="fw-bold">
                Infrastructures of occupation: apparatuses of russian
                (re)coloniality
              </span>
            </p>

            <p className="news-next ms-4">
              <span className="text-decoration-underline">
                I: SPATIALITIES &amp; ECONOMIES
              </span>
              <br />
              Thu, November 21, 12:00 to 1:45pm
            </p>

            <p className="news-text ms-4">
              Ecem Saricayir, Cornell University
              <br />
              <div className="fw-bold ms-4">
                From migrants to settlers: A study of russian rural development
                at the borderlands
              </div>
            </p>

            <p className="news-text ms-4">
              Aleksandra Simonova, UC Berkeley
              <br />
              <div className="fw-bold ms-4">
                Chersonesos: Governing the “russian world” through
                reconstruction and blagoustroistvo
              </div>
            </p>

            <p className="news-text ms-4">
              Vera Smirnova, Kansas State University
              <br />
              <div className="fw-bold ms-4">
                russia’s property regimes as a spatial technology of occupation
              </div>
            </p>

            <p className="news-text ms-4">
              Michał Murawski, University College London
              <br />
              <div className="fw-bold ms-4">
                Dark reconstruction, or russian worlding:
                architecture-after-urbicide in Ukraine, Chechnya and Syria
              </div>
            </p>

            <p className="news-text ms-4">
              <span className="fw-bold">Chair: </span>Da Hyung Jeong, Princeton
              University
              <br />
              <span className="fw-bold">Discussant: </span>Nikolay Erofeev,
              University of Kassel
            </p>

            <p className="news-next ms-4">
              <span className="text-decoration-underline">
                II: IDEOLOGIES &amp; ECOLOGIES
              </span>
              <br />
              Thu, November 21, 2:00 to 3:45pm
            </p>

            <p className="news-text ms-4">
              Makar Tereshin, University College London
              <br />
              <div className="fw-bold ms-4">
                Dark skies: the political ecology of the Baikonur Cosmodrome
              </div>
            </p>

            <p className="news-text ms-4">
              Da Hyung Jeong, Princeton University
              <br />
              <div className="fw-bold ms-4">
                Langston Hughes in Chirchiq, a Model Soviet Town in Socialist
                Uzbekistan
              </div>
            </p>

            <p className="news-text ms-4">
              Francisco Martinez, Tampere University
              <br />
              <div className="fw-bold ms-4">
                New Hideouts for an Old Fear. Energy and Military
                Infrastructures in Estonia’s Siberia
              </div>
            </p>

            <p className="news-text ms-4">
              <span className="fw-bold">Chair: </span>Michał Murawski,
              University College London
              <br />
              <span className="fw-bold">Discussant: </span>Victoria Donovan,
              University of St Andrews
            </p>

            <p className="news-next ms-4">
              <span className="text-decoration-underline">
                III: RESIDUES &amp; CONTESTATIONS
              </span>
              <br />
              Thu, November 21, 4:00 to 5:45pm
            </p>

            <p className="news-text ms-4">
              Malgorzata Łukianow, University of Warsaw/University of Michigan
              <br />
              <div className="fw-bold ms-4">
                Splendid Isolation: the (invisibility of) russian imperial
                heritage in Poland
              </div>
            </p>

            <p className="news-text ms-4">
              Oliver Banatvala, University College London
              <br />
              <div className="fw-bold ms-4">
                Wartime malleability: peace and war within the everyday urban
                realm
              </div>
            </p>

            <p className="news-text ms-4">
              Zayra Badillo-Castro, Center for Advanced Studies of Puerto Rico
              and the Caribbean
              <br />
              <div className="fw-bold ms-4">
                Soviet infrastructural legacies and ‘collapses’ in Havana and
                Tashkent
              </div>
            </p>

            <p className="news-text ms-4">
              Natalia Tchermalykh, Université de Genève
              <br />
              <div className="fw-bold ms-4">
                Performative Curating: the Ukrainian Decolonial Agenda in
                Western Museums since 2014
              </div>
            </p>

            <p className="news-text ms-4">
              <span className="fw-bold">Chair: </span>Ada Wordsworth, University
              College London
              <br />
              <span className="fw-bold">Discussant: </span>Adam Przywara,
              Universität Fribourg
            </p>

            <p className="news-text ms-4">
              Part B<br />
              <span className="fw-bold">
                Infrastructures of Liberation: resistance, reconstruction and
                de- occupation in the Ukrainian commons
              </span>
            </p>

            <p className="news-next ms-4">
              <span className="text-decoration-underline">
                I: SPATIALITIES &amp; ECONOMIES
              </span>
              <br />
              Fri, November 22, 10:00 to 11:45am
            </p>

            <p className="news-text ms-4">
              Emily Channell-Justice, Harvard University
              <br />
              <div className="fw-bold ms-4">
                Self-organisation as liberation
              </div>
            </p>

            <p className="news-text ms-4">
              Ada Wordsworth, University College London
              <br />
              <div className="fw-bold ms-4">
                Accidentally decentralised reconstruction in Ukraine: the
                benefits and perils of the grassroots approach
              </div>
            </p>

            <p className="news-text ms-4">
              Yuliya Yurchenko, Greenwich University
              <br />
              <div className="fw-bold ms-4">
                Transnational polycentricity and political prefiguration of
                Ukraine’s social resistance
              </div>
            </p>

            <p className="news-text ms-4">
              Daria Anosova, University College London
              <br />
              <div className="fw-bold ms-4">
                The Big Flat Construction Site: Contemporary Art Practice and
                Organization in Ukraine Under the Full-Scale Invasion
              </div>
            </p>

            <p className="news-text ms-4">
              <span className="fw-bold">Chair: </span>Ada Wordsworth, University
              Sofia Dyak, Center for Urban History, Lviv
              <br />
              <span className="fw-bold">Discussant: </span>Adam Przywara,
              Adriana Petryna, University of Pennsylvania
            </p>

            <p className="news-next ms-4">
              <span className="text-decoration-underline">
                II: RESIDUES &amp; AMBIVALENCES
              </span>
              <br />
              Fri, November 22, 1:30 to 3:15pm
            </p>

            <p className="news-text ms-4">
              Darya Tsymbalyuk, University of Chicago (tsymbalyuk@uchicago.edu)
              <br />
              <div className="fw-bold ms-4">
                The many returns; to over-exist
              </div>
            </p>

            <p className="news-text ms-4">
              Svitlana Biedarieva, Universidad Iberoamericana, Ciudad de México
              &amp; Kyiv School of Economics
              <br />
              <div className="fw-bold ms-4">
                Ambicoloniality and the Decolonization of Ukrainian Culture
              </div>
            </p>

            <p className="news-text ms-4">
              Victoria Donovan, University of St Andrews
              <br />
              <div className="fw-bold ms-4">
                De-occupying the archive: Art as liberatory practice
              </div>
            </p>

            <p className="news-text ms-4">
              Olexii Kuchanskyy, Bauhaus University Weimar
              <br />
              <div className="fw-bold ms-4">
                Colonial Remnants in the Infrastructure of Archives
              </div>
            </p>

            <p className="news-text ms-4">
              <span className="fw-bold">Chair/Discussant: </span>Samuel
              Veremchuk, U. of Illinois at Urbana-Champaign
            </p>
          </div>

          <hr></hr>
          <div className="news-item">
            <h1 className="news-header">
              Unsettling Experimentation in Anthropology: Introducing a Network
              of Problems
            </h1>
            <p className="news-date">11 November 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/News-2.jpeg"
                alt="News"
                className="news-img"
              />
            </div>
            <h3 className="news-section-header">Overview</h3>
            <p className="news-text ms-4 fw-bold">
              Experimentation is becoming increasingly common in anthropology.
              Sometimes it unsettles traditional observational practices, opens
              the way for radical novel forms of representation or challenges
              the fixation of anthropology with a restricted notion of
              anthropos.
            </p>
            <p className="news-text ms-4 fw-bold">
              In this encounter, we want to think, reflect and speculate
              together how experimentation can challenge common instantiations
              and concerns within anthropology. Although experimentation
              unsettles some of our disciplinary foundations, we think this
              reorientation is worth pursuing for it opens a relevant network of
              problems for our discipline.
            </p>
            <p className="news-text ms-4 fw-bold">
              The workshop is organised in in two parts: First, the new
              convening team and two invited speakers reflect upon possibilities
              and challenges of mobilising ‘the experimental’ in different
              research context. Then we shall discuss pathways for the network’s
              future trajectories with the general public and the host
              laboratories at the Institute for European Ethnology.
            </p>
            <p className="news-text ms-4 fw-bold">
              <span className="news-underline">Convenors</span>: Adolfo
              Estalella, Elisabeth Luggauer, Maka Suarez, and Jonas Tinius
            </p>
            <p className="news-text ms-4 news-underline">Talks</p>
            <p className="news-text ms-4">
              Francisco Martínez,{" "}
              <span className="fw-bold">
                Mutual Redefinitions: Anthropology as a Practice and as a
                Discipline
              </span>
            </p>
            <p className="news-text ms-4">
              This paper provides a new take on the possibilities – and limits –
              of experimental collaborations for ethnographic research and how
              they might lead to shifts back in the disciplinary core. By
              showing how exhibitions can be used for knowledge-making, it
              expands the notion of field and fieldwork. The series of
              ethnographic experiments outlined here help us rethink and,
              eventually, unlearn our own tools and protocols of relevance in
              anthropology. Unlearning, however, is not what researchers are
              supposed to do in the field. Too-often boundary-work removes what
              gives rise to wonder in order to secure what counts as valid
              knowledge and by whom. A more inclusive take on fieldwork could
              benefit practitioners and the discipline itself, as
              anthropologists are seeking to reach wider publics and navigate
              the contemporary complexities of authority and representation.
            </p>
            <p className="news-text ms-4">
              Kiven Strohm,{" "}
              <span className="fw-bold">
                (I want to live on an) Experimental Plane: Diffracting art and
                anthropology collaborations
              </span>
            </p>
            <p className="news-text ms-4">
              When two practices or disciplines come together, whether formally
              or informally, too often one is subordinated to the other, or
              worse, they are asked to produce a bastard transdisciplinary child
              that blurs the boundaries between their different skills and modes
              of thought. For some years, those of us working in
              art/anthropology collaborations have sought to counter this
              tendency, proposing different approaches, such as “of/with/and”
              (Clarke). Working through these efforts, what I wish to share with
              all of you is an experiment. I want to further explore
              art/anthropology collaborations through two angles. First, I wish
              to elaborate on these collaborations by way of a diffractive
              “method” (Haraway, Barad) to consider the interference patterns
              that emerge when reading these different practices through each
              other. My plan is to maintain and heighten the distinct skills and
              modes of thought in these practices, and, in so doing, consider
              how this might produce new forms of inquiry that engenders
              creative and unexpected outcomes. Second, I want to then consider
              how this might inform an art/anthropology research practice as a
              ‘plane of experimentation’ for what art and anthropology can do,
              together/apart. It will be a bit of adventure, and, I hope, the
              opening of a new terrain.
            </p>
            <h3 className="news-section-header">Schedule</h3>
            <div className="news-schedule ms-4">
              <p className="news-underline">
                <span>November 15, 2024</span>
              </p>
              <p className="news-underline">#Colleex Workshop</p>
              <p>
                Institute for European Ethnology, Humboldt University of Berlin
              </p>
              <p>14.30 - 17.30 pm</p>
            </div>
          </div>
          <hr></hr>
          <div className="news-item">
            <h1 className="news-header">
              Redesigning the Social through Ethnographic Experimentation
            </h1>
            <p className="news-date">5 October 2024</p>
            <div className="news-img-container d-flex align-items-center">
              <img
                src="media/news-imgs/Flamingot news.jpg"
                alt="News"
                className="news-img"
              />
            </div>
            <h3 className="news-section-header">Overview</h3>
            <p className="news-text ms-4 fw-bold">
              This seminar is an appeal to sketching out experimental
              ethnographic interventions, while aiming to work out what
              constitutes, both by extension and comprehension, the concept of
              the social. Through a series of open-format presentations, we’ll
              reflect on how field research can potentially enact, perform, or
              stage inventive ways of composing the world, expanding in turn the
              role of social research in the contemporary.
            </p>
            <h3 className="news-section-header">Schedule</h3>
            <div className="news-schedule ms-4">
              <p className="news-underline">
                <span>November 4, 2024</span>
              </p>
              <p className="news-underline">
                Metsätalo, C505 (sali 31, 5th floor), Unioninkatu 40, University
                of Helsinki
              </p>
              <p>Organiser: Francisco Martínez (Tampere University)</p>
              <p className="news-underline">Provisory Schedule:</p>
              <p>10.00</p>
              <p>Patrick Laviolette (Masaryk University)</p>
              <p>Adolfo Estalella (Spanish National Research Council)</p>
              <p>Eeva Berglund (Aalto University, Helsinki)</p>
              <p>Guy Julier (Aalto University, Helsinki)</p>
              <p>12.00</p>
              <p>Lunch</p>
              <p>13.00</p>
              <p>
                Tomás Errázuriz &amp; Ricardo Greene (Universidad Andrés Bello
                &amp; San Sebastián, Chile)
              </p>
              <p>Eerika Koskinen-Koivisto (University of Jyväskylä)</p>
              <p>
                Olli Pyyhtinen, Sonja Lampinen &amp; Niina Uusitalo (Tampere
                University)
              </p>
              <p>14.30</p>
              <p>Aurora del Rio and Katri Naukkarinen (Aalto University)</p>
              <p>Erich Berger (University of Oulu)</p>
              <p>Nastasia Fomina (Aalto University)</p>
              <p>Gregoire Rousseau (Station of commons, Lumbung Radio)</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
